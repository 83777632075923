import { IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonGrid, IonImg, IonItem, IonItemDivider, IonItemGroup, IonLabel, IonList, IonRow, IonText } from '@ionic/react';
import './HomeContainer.css';

interface ContainerProps {
  name: string;
}

const HomeContainer: React.FC<ContainerProps> = ({ name }) => {
  return (
    <IonGrid>
      <IonRow>
        <IonCard color="dark">
          <IonCardHeader>
            <IonCardTitle>
              <IonText color="light">A fresh start</IonText>
            </IonCardTitle>
            <IonCardSubtitle></IonCardSubtitle>
          </IonCardHeader>

          <IonCardContent>
            <IonImg src="assets/field_at_dawn_1920x1440.jpg" alt="Baseball field at dawn" />
            <IonText color="light">
              <h2><strong>A new dawn has come to Eula Youth Baseball!</strong></h2>
              The coming year will be full of excitment, challenges, and fun.
              We are dedicated to providing the best experience possible for our youth, parents, and opponents.
              And with your help we can deliver a year of baseball like no other!
              The phrase "It takes a village" has never been more appropriate than now!
              Come with us as our youth explore their love of baseball.
            </IonText>
          </IonCardContent>
        </IonCard>
      </IonRow>
      <IonRow>
        <IonCard color="dark">
          <IonCardHeader>
            <IonCardTitle>
              <IonText>Upcoming Events</IonText>
            </IonCardTitle>
            <IonCardSubtitle></IonCardSubtitle>
          </IonCardHeader>
          <IonCardContent>
            <IonList lines="inset" >
              <IonItemGroup>
                <IonItem color="light">
                  <IonText>Season starts first weekend in April with Quad County Organization</IonText>
                </IonItem>
                <IonItemDivider></IonItemDivider>
                <IonItem color="light">
                  <IonText>Teams can start practicing week of March 20</IonText>
                </IonItem>
              </IonItemGroup>
            </IonList>
          </IonCardContent>
        </IonCard>
        <IonCard color="dark">
          <IonCardHeader>
            <IonCardTitle>
              <IonText>Updates</IonText>
            </IonCardTitle>
            <IonCardSubtitle></IonCardSubtitle>
          </IonCardHeader>
          <IonCardContent>
            <IonText>Current update info</IonText>
          </IonCardContent>
        </IonCard>
      </IonRow>
    </IonGrid>
  );
};

export default HomeContainer;
