import { IonAvatar, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonGrid, IonHeader, IonLabel, IonRow, IonText } from '@ionic/react';
import './OrgContainer.css';

interface ContainerProps {
  name: string;
}

const OrgContainer: React.FC<ContainerProps> = ({ name }) => {
  return (
    <IonGrid>
      <IonRow>
        <IonCard color="dark">
          <IonCardHeader>{name}</IonCardHeader>
        </IonCard>
      </IonRow>
      <IonRow>
        <IonCard color="medium">
          <IonCardHeader>
            <IonCardTitle>
              <a href="mailto:eulayouthbaseball@gmail.com?subject = Feedback">Email Us</a>
            </IonCardTitle>
          </IonCardHeader>
        </IonCard>
      </IonRow>
      <IonRow>
        <IonCard color="dark">
          <IonCardHeader>
            <IonCardTitle>
              <IonText color="light">President</IonText></IonCardTitle>
            <IonCardSubtitle>
              <IonAvatar>
                <img alt="" src="https://ionicframework.com/docs/img/demos/avatar.svg" />
              </IonAvatar>
              <IonText color="light">Ethan White</IonText>
            </IonCardSubtitle>
          </IonCardHeader>
          <IonCardContent>
            <IonText color="light">Bio</IonText>
          </IonCardContent>
        </IonCard>
        <IonCard color="dark">
          <IonCardHeader>
            <IonCardTitle>
              <IonText color="light">Vice President</IonText></IonCardTitle>
            <IonCardSubtitle>
              <IonAvatar>
                <img alt="" src="https://ionicframework.com/docs/img/demos/avatar.svg" />
              </IonAvatar>
              <IonText color="light">Corey Jenkins</IonText>
            </IonCardSubtitle>
          </IonCardHeader>
          <IonCardContent>
            <IonText color="light">Bio</IonText>
          </IonCardContent>
        </IonCard>
        <IonCard color="dark">
          <IonCardHeader>
            <IonCardTitle>
              <IonText color="light">Treasurer</IonText></IonCardTitle>
            <IonCardSubtitle>
              <IonAvatar>
                <img alt="" src="https://ionicframework.com/docs/img/demos/avatar.svg" />
              </IonAvatar>
              <IonText color="light">Cody Bob Williams</IonText>
            </IonCardSubtitle>
          </IonCardHeader>
          <IonCardContent>
            <IonText color="light">Bio</IonText>
          </IonCardContent>
        </IonCard>
        <IonCard color="dark">
          <IonCardHeader>
            <IonCardTitle>
              <IonText color="light">Secretary</IonText></IonCardTitle>
            <IonCardSubtitle>
              <IonAvatar>
                <img alt="" src="https://ionicframework.com/docs/img/demos/avatar.svg" />
              </IonAvatar>
              <IonText color="light">Chealsea Quick</IonText>
            </IonCardSubtitle>
          </IonCardHeader>
          <IonCardContent>
            <IonText color="light">Bio</IonText>
          </IonCardContent>
        </IonCard>
        <IonCard color="dark">
          <IonCardHeader>
            <IonCardTitle>
              <IonText color="light">Board Member</IonText></IonCardTitle>
            <IonCardSubtitle>
              <IonAvatar>
                <img alt="" src="https://ionicframework.com/docs/img/demos/avatar.svg" />
              </IonAvatar>
              <IonText color="light">Cody Elam</IonText>
            </IonCardSubtitle>
          </IonCardHeader>
          <IonCardContent>
            <IonText color="light">Bio</IonText>
          </IonCardContent>
        </IonCard>
      </IonRow>
    </IonGrid>
  );
};

export default OrgContainer;
