import { IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonGrid, IonLabel, IonRow, IonSegment, IonText, IonSegmentButton } from '@ionic/react';
import { useState } from 'react';
import './TeamsContainer.css';

interface ContainerProps {
  name: string;
}

const TeamsContainer: React.FC<ContainerProps> = ({ name }) => {
  const [team, setTeam] = useState("Choose a Team");
  return (
    <IonGrid>
      <IonRow>
        <IonCard color="dark">
          <IonCardHeader>
            <IonCardTitle>
              <IonText>{name}</IonText>
            </IonCardTitle>
            <IonSegment
              onIonChange={e => {
                setTeam(getTeam(e.detail.value ? e.detail.value : "") + " Team Info");
                console.log(e.detail.value)
              }}
              scrollable={true}>
              <IonSegmentButton value="teeball1">
                <IonLabel><IonText color="light">TBall 1</IonText></IonLabel>
              </IonSegmentButton>
              <IonSegmentButton value="teeball2">
                <IonLabel><IonText color="light">TBall 2</IonText></IonLabel>
              </IonSegmentButton>
              <IonSegmentButton value="machinepitch">
                <IonLabel><IonText color="light">MP</IonText></IonLabel>
              </IonSegmentButton>
              <IonSegmentButton value="8u">
                <IonLabel><IonText color="light">8U</IonText></IonLabel>
              </IonSegmentButton>
              <IonSegmentButton value="10u1">
                <IonLabel><IonText color="light">10U 1</IonText></IonLabel>
              </IonSegmentButton>
              <IonSegmentButton value="10u2">
                <IonLabel><IonText color="light">10U 2</IonText></IonLabel>
              </IonSegmentButton>
              <IonSegmentButton value="12u">
                <IonLabel><IonText color="light">12U</IonText></IonLabel>
              </IonSegmentButton>
            </IonSegment>
          </IonCardHeader>

          <IonCardContent>
          </IonCardContent>
        </IonCard>

      </IonRow>
      <IonRow>
        <IonCard color="dark">
          <IonCardHeader>
            <IonCardTitle>
              <IonText color="light"><h1>{team}</h1></IonText>
            </IonCardTitle>
            <IonCardSubtitle></IonCardSubtitle>
          </IonCardHeader>
          <IonCardContent>

          </IonCardContent>
        </IonCard>
      </IonRow>
    </IonGrid>
  );
};

function getTeam(id: string) {
  switch (id) {
    case 'teeball1':
      return 'TeeBall 1';
    case 'teeball2':
      return 'TeeBall 2';
    case 'machinepitch':
      return 'Machine Pitch';
    case '8u':
      return '8U';
    case '10u1':
      return '10U 1';
    case '10u2':
      return '10U 2';
    case '12u':
      return '12U';
    default:
      return 'Choose a team';
  }
}

export default TeamsContainer;
