import { IonContent, IonHeader, IonPage, IonText, IonTitle, IonToolbar } from '@ionic/react';
import TeamsContainer from '../components/TeamsContainer';
import './Teams.css';

const Teams: React.FC = () => {
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="dark">
          <IonTitle>
            <IonText>Teams</IonText>
          </IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">
              <IonText color="light">Teams</IonText>
            </IonTitle>
          </IonToolbar>
        </IonHeader>
        <TeamsContainer name='Your Teams' />
      </IonContent>
    </IonPage>
  );
};

export default Teams;
